








































import Vue from 'vue';
import Component from 'vue-class-component';
import AppUserOrderItem from './UserOrderItem.vue';

@Component({
  components: { AppUserOrderItem },

  props: {
    linkComponent: String,
    objectUrl: Function,
    limit: Number,
  },
})
export default class UserOrderItems extends Vue {
  // props
  limit: number;
  linkComponent: string;
  objectUrl: (orderItem: any) => any;

  // data
  loading: boolean = true;
  nextPageloading: boolean = false;

  page: number = 0;
  hasNextPage: boolean = false;

  items: UserOrder[] = [];

  async created() {
    await this.fetchOrders();

    this.loading = false;
  }

  async fetchOrders() {
    const params = {
      offset: this.page * (this.limit),
      limit: this.limit + 1,
    };

    try {
      this.nextPageloading = true;
      const orders = await this.$api.user.OrdersList<UserOrder[]>(params);
      /* Т.к. ScoreRating не самостоятельный продукт и всегда заказывается вместе с CreditRating, то заменяем его на CreditRating */
      orders.forEach(order => {
        if (order.OrderItem[0].ProductName === 'ScoreRating') {
          order.OrderItem[0] = order.OrderItem[1];
        }
      });
      this.nextPageloading = false;
      this.hasNextPage = orders.length > this.limit;
      this.items.push(...orders.slice(0, this.limit));
      this.page += 1;
    } catch (error) {
      console.log(error);
    }
  }
}
