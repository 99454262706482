








































import Vue from 'vue';
import Clipboard from 'clipboard';

let clipboard: Clipboard;

export default Vue.extend({
  props: {},

  data() {
    return {
      loading: true,
      apiKey: null,
    };
  },

  async created() {
    clipboard = new Clipboard('[data-clipboard-text]');

    clipboard.on('success', () => {
      this.$noty.info({ text: 'Ключ скопирован в буфер обмена' });
    });

    try {
      const data = await this.$api.apiToken.Get();
      this.apiKey = data.api_key;
    } catch (error) {
      console.error(error);
    }
    this.loading = false;
  },

  beforeDestroy() {
    if (clipboard) {
      clipboard.destroy();
      clipboard = null;
    }
  },

  methods: {
    async createOrUpdateToken() {
      this.loading = true;
      try {
        const data = await this.$api.apiToken.CreateOrUpdate();
        this.apiKey = data.api_key;
      } catch (error) {
        console.error(error);
        this.$noty.error({ text: 'Произошла ошибка при запросе' });
      }
      this.loading = false;
    },
  },
});
