var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-settings" },
    [
      _c(
        "div",
        { staticClass: "page-wrapper page-wrapper_sm" },
        [_c("app-settings-update-info"), _c("app-settings-change-password")],
        1
      ),
      _c("app-api-settings"),
      _c("app-change-role"),
      _c("app-payers"),
      _c("app-account-deletion")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }