var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "page-wrapper page-wrapper_sm d-sm-flex align-items-center justify-content-between"
    },
    [
      _c("h4", { staticClass: "m-sm-0 mb-3" }, [
        _vm._v("\n    Редактирование роли\n  ")
      ]),
      _c(
        "app-button",
        { attrs: { size: "sm" }, on: { click: _vm.openDialog } },
        [_vm._v("\n    Сменить роль\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }