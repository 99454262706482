var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "popup__body" }, [
    _c("h4", { staticClass: "text-center" }, [
      _vm._v("\n    Подтверждение удаления\n  ")
    ]),
    _vm._m(0),
    _c(
      "div",
      { staticClass: "d-flex btns" },
      [
        _c(
          "app-button",
          {
            attrs: { variant: "danger", loading: _vm.loading },
            on: { click: _vm.deleteAccount }
          },
          [_vm._v("\n      Да, удалить\n    ")]
        ),
        _c(
          "app-button",
          {
            attrs: { variant: "light" },
            on: {
              click: function($event) {
                return _vm.$dialog.close()
              }
            }
          },
          [_vm._v("\n      Отмена\n    ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "text-center" }, [
      _vm._v("\n    Действительно ли желаете удалить аккаунт? "),
      _c("br"),
      _vm._v(
        "\n    Все данные будут удалены. После удаления все заказанные выписки уже не будут доступны.\n  "
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }