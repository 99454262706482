












import Vue from 'vue';
import AppSettingsChangePassword from './SettingsChangePassword.vue';
import AppSettingsUpdateInfo from './SettingsUpdateInfo.vue';
import AppAccountDeletion from './AccountDeletion.vue';
import AppApiSettings from './ApiSettings.vue';
import AppPayers from '@/views/UserProfile/UserSettings/Payers.vue';
import AppChangeRole from '@/views/UserProfile/UserSettings/ChangeRole.vue';

export default Vue.extend({
  components: {
    AppSettingsChangePassword,
    AppSettingsUpdateInfo,
    AppAccountDeletion,
    AppApiSettings,
    AppChangeRole,
    AppPayers,
  },
});
