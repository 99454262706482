









































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Validators } from 'shared/utils/validator';
@Component({
  name: 'AppEditPayerForm',
  popupOptions: {
    name: 'popup_side popup_payer',
  },
  props: {
    onSubmit: Function,
    payerData: {
      type: Object,
      required: true,
    },
  },
})

export default class EditPayerForm extends Vue {
  // props
  payerData: Payer;
  onSubmit: Function;

  // data
  payer: Payer = {
    inn: '',
    kpp: '',
    ogrn: '',
    organisation_name: '',
    legal_address: '',
    head: '',
    bik: '',
    checking_account: '',
    bank_name: '',
    correspondent_account: '',
    fio: '',
    phone: '',
    email: '',
  };

  loading: boolean = false;

  // methods
  showErrorMessage() {
    this.$noty.error({ text: 'Пожалуйста, заполните все поля' });
  }

  validationConfig(): ValidationConfig {
    const config: ValidationConfig = {
      inn: [ Validators.required, Validators.positiveNumber, Validators.min(12, 'должно быть 12 цифр'), Validators.max(12, 'должно быть 12 цифр') ],
      ogrn: [ Validators.required, Validators.positiveNumber, Validators.min(15, 'должно быть 15 цифр'), Validators.max(15, 'должно быть 15 цифр') ],
      organisation_name: [ Validators.required, Validators.min(1) ],
      legal_address: [ Validators.required, Validators.min(1) ],
      head: [ Validators.required, Validators.min(2) ],
      bik: [ Validators.required, Validators.min(9, 'должно быть 9 цифр'), Validators.max(9, 'должно быть 9 цифр') ],
      checking_account: [ Validators.required, Validators.positiveNumber, Validators.min(20, 'должно быть 20 цифр'), Validators.max(20, 'должно быть 20 цифр') ],
      bank_name: [ Validators.required, Validators.min(2) ],
      correspondent_account: [ Validators.required, Validators.positiveNumber, Validators.min(20, 'должно быть 20 цифр'), Validators.max(20, 'должно быть 20 цифр') ],
    };
    if (this.payer.kpp) {
      config.kpp = [ Validators.min(9, 'должно быть 9 цифр'), Validators.max(9, 'должно быть 9 цифр') ];
    }
    if (this.payer.fio) {
      config.fio = [ Validators.min(2) ];
    }
    if (this.payer.phone) {
      config.phone = [ Validators.phone ];
    }
    if (this.payer.email) {
      config.email = [ Validators.email ];
    }
    return config;
  }

  submit() {
    this.loading = true;
    this.onSubmit(this.payer);
  }

  // lifecycle hooks
  created() {
    this.payer = this.payerData;
  }
}

