





























































































































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { saveAs } from 'file-saver';
import get from 'lodash/get';

@Component({
  props: {
    item: Object,
    objectUrl: Function,
    linkComponent: String,
  },
})
export default class UserOrderItem extends Vue {
  // props
  item: UserOrder;
  linkComponent: string;
  objectUrl: (orderItem: any) => any;

  isExpanded: boolean = false;
  loading: boolean = false;
  get = get;

  // computed
  get kadastrNumber(): string {
    for (const item of this.item.OrderItem) {
      if (
        item.ItemMetadata.kadastr_number &&
        item.ItemMetadata.kadastr_number !== 'n/a'
      ) {
        return item.ItemMetadata.kadastr_number;
      } else {
        return item.OrderItemID;
      }
    }

    return '';
  }

  get address(): string {
    for (const item of this.item.OrderItem) {
      if (item.ItemMetadata.address && item.ItemMetadata.address !== 'n/a') {
        return item.ItemMetadata.address;
      }
    }

    return '';
  }

  get isCheckOwner(): boolean {
    return (
      this.item.OrderItem.length === 1 &&
      [ 'CheckOwner', 'CheckOwnerV2' ].includes(
        this.item.OrderItem[0].ProductName
      )
    );
  }

  get isIndependentReport(): boolean {
    return (
      this.item.OrderItem.length === 1 &&
        this.item.OrderItem[0].ProductName === 'IndependentReportAI'
    );
  }

  get isCounterAgent(): boolean {
    return (
      this.item.OrderItem.length === 1 &&
        this.item.OrderItem[0].ProductName === 'CounterAgent'
    );
  }

  get isCheckAuto(): boolean {
    return (
      this.item.OrderItem.length === 1 &&
        this.item.OrderItem[0].ProductName === 'CheckAuto'
    );
  }

  get isCostAssessment(): boolean {
    return (
      this.item.OrderItem.length === 1 &&
      this.item.OrderItem[0].ProductName === 'CostAssessment'
    );
  }

  get isCheckCreditRating(): boolean {
    return this.item.OrderItem[0].ProductName === 'CreditRating';
  }

  get isWholesaleOrder(): boolean {
    let kadastrNumber = null;

    for (const orderItem of this.item.OrderItem) {
      if (
        kadastrNumber &&
        orderItem.ItemMetadata.kadastr_number !== kadastrNumber
      ) {
        return true;
      }

      kadastrNumber = orderItem.ItemMetadata.kadastr_number;
    }

    return false;
  }

  get total(): number {
    return this.item.OrderItem.length;
  }

  get completedCount(): number {
    let count = 0;

    for (const orderItem of this.item.OrderItem) {
      if (orderItem.status === 'done') {
        count += 1;
      }
    }

    return count;
  }

  get progress(): object {
    const percent: number = Math.round(
      (this.completedCount / this.total) * 100
    );

    return {
      width: `${percent}%`,
    };
  }

  get isPhysical() {
    if (this.item.OrderItem[0].metadata && this.item.OrderItem[0].metadata.ownersData) {
      return this.item.OrderItem[0].metadata.ownersData[0].owner_type === 0;
    }
    return true;
  }

  get description() {
    if (this.item.OrderItem[0].metadata.costAssessmentData) {
      return this.item.OrderItem[0].metadata.costAssessmentData.address;
    }
    return '';
  }

  get productZipLink(): string {
    return 'https://api.realtycloud.ru/download_order?orderID=' + this.item.OrderID;
  }

  // methods
  getName(name: string) {
    switch (name) {
      case 'EgrnObject':
        return 'Отчет об объекте';
      case 'EgrnRightList':
        return 'Отчет о переходе прав';
    }
  }

  expand() {
    this.isExpanded = !this.isExpanded;
  }

  getStatusText(status: string) {
    if (status === 'done') {
      return 'Выполнен';
    }

    if (status !== 'waiting_for_payment') {
      return 'В работе';
    }

    return 'Не оплачен';
  }

  getFullName(orderItem: OrderItem): string {
    if (orderItem.metadata.ownersData) {
      return `${orderItem.metadata.ownersData[0].surname} ${orderItem.metadata.ownersData[0].first} ${orderItem.metadata.ownersData[0].patronymic}`;
    }
    return '';
  }

  getField(fieldName: string) {
    return get(this.item.OrderItem[0], 'metadata.ownersData.0.' + fieldName);
  }

  async download(url: string, fileType: string, params?: any) {
    let response: Blob;
    this.loading = true;
    try {
      response = await this.$api.raw.files.GetFileBlob(url, params);
    } catch (error) {
      this.$noty.error({ text: 'Произошла ошибка при скачивании, пожалуйста, попробуйте еще раз' });
      // Если не скачивается pdf, то для выписок пробуем скачать html
    }
    this.loading = false;
    // const link = document.createElement('a');
    // link.href = URL.createObjectURL(response);
    // link.download = `${fileName}.${fileType}`;
    // document.body.append(link);
    // link.click();
    // link.remove();
    // window.open(URL.createObjectURL(response), '_blank');

    saveAs(response, `${'orders_wholesale'}.${fileType}`);
  }
}
