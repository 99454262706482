var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-wrapper page-wrapper_sm" }, [
    _c(
      "div",
      { staticClass: "d-sm-flex align-items-center justify-content-between" },
      [
        _c("h4", { staticClass: "mb-sm-0 mb-3" }, [
          _vm._v("\n      Управление API ключом\n    ")
        ]),
        _c(
          "app-button",
          {
            attrs: { loading: _vm.loading, size: "sm" },
            on: { click: _vm.createOrUpdateToken }
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(_vm.apiKey ? "Обновить" : "Создать") +
                " API ключ\n    "
            )
          ]
        )
      ],
      1
    ),
    _vm.apiKey
      ? _c("div", { staticClass: "mt-4" }, [
          _c("div", { staticClass: "form-row" }, [
            _vm._m(0),
            _c("div", { staticClass: "col-auto" }, [
              _c("span", [_vm._v(_vm._s(_vm.apiKey))]),
              _c(
                "span",
                {
                  staticClass: "btn-link small",
                  attrs: { "data-clipboard-text": _vm.apiKey }
                },
                [_vm._v("\n          скопировать\n        ")]
              ),
              _c("div", { staticClass: "small text-muted" }, [
                _vm._v(
                  "\n          После смены API ключа, старый будет недействителен\n        "
                )
              ])
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-auto text-truncate" }, [
      _c("b", [_vm._v("Ваш API ключ:")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }