var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-form",
    {
      staticClass: "settings-section",
      attrs: {
        "request-handler": _vm.onSubmit,
        model: _vm.model,
        rules: _vm.validationRules
      },
      scopedSlots: _vm._u([
        {
          key: "button",
          fn: function(ref) {
            var loading = ref.loading
            return [
              _c(
                "app-button",
                { attrs: { loading: loading, type: "submit" } },
                [_vm._v("\n      Сменить\n    ")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("h4", { staticClass: "mb-3" }, [_vm._v("\n    Сменить пароль\n  ")]),
      _c(
        "div",
        { staticClass: "row mx-md-n3 m-0" },
        [
          _c(
            "app-form-group",
            {
              staticClass: "col-md-6 px-md-3 p-0",
              attrs: { "model-name": "password_old" }
            },
            [
              _c(
                "app-input",
                {
                  attrs: { placeholder: "Старый пароль", type: "password" },
                  model: {
                    value: _vm.model.password_old,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "password_old", $$v)
                    },
                    expression: "model.password_old"
                  }
                },
                [
                  _c("svg", [
                    _c("use", { attrs: { "xlink:href": "#icon-key" } })
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row mx-md-n3 m-0" },
        [
          _c(
            "app-form-group",
            {
              staticClass: "col-md-6 px-md-3 p-0",
              attrs: { "model-name": "password_new" }
            },
            [
              _c(
                "app-input",
                {
                  attrs: { placeholder: "Новый пароль", type: "password" },
                  model: {
                    value: _vm.model.password_new,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "password_new", $$v)
                    },
                    expression: "model.password_new"
                  }
                },
                [
                  _c("svg", [
                    _c("use", { attrs: { "xlink:href": "#icon-key" } })
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "app-form-group",
            {
              staticClass: "col-md-6 px-md-3 p-0",
              attrs: { "model-name": "password_new" }
            },
            [
              _c(
                "app-input",
                {
                  attrs: {
                    placeholder: "Повторите новый пароль",
                    type: "password"
                  },
                  model: {
                    value: _vm.confirmPass,
                    callback: function($$v) {
                      _vm.confirmPass = $$v
                    },
                    expression: "confirmPass"
                  }
                },
                [
                  _c("svg", [
                    _c("use", { attrs: { "xlink:href": "#icon-key" } })
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }