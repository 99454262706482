




























































import Vue from 'vue';
import Component from 'vue-class-component';
import { Validators } from '@/core/utils/validator';

@Component
export default class SettingsChangePassword extends Vue {
  // data
  model: UpdatePasswordData = {
    password_old: '',
    password_new: '',
  };
  confirmPass: string = '';

  // methods
  validationRules(): ValidationConfig {
    return {
      password_old: [ Validators.required ],
      password_new: [ Validators.required, Validators.eq(this.confirmPass, 'Пароли не совпадают') ],
    };
  }

  async onSubmit() {
    try {
      await this.$store.dispatch('user/changePassword', this.model);

      this.model.password_old = '';
      this.model.password_new = '';
      this.confirmPass = '';

      this.$noty.success({ text: 'Пароль успешно обновлен' });
    } catch (error) {
      this.$noty.error({ text: 'Произошла ошибка при обновлении пароля' });
      console.error(error);
    }
  }
}
