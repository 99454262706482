var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.loading
        ? _c("app-loader", { attrs: { text: "Загрузка заказов" } })
        : [
            !_vm.items.length
              ? [
                  _c(
                    "div",
                    {
                      staticClass:
                        "user-orders-empty p-4 text-center text-muted"
                    },
                    [_vm._v("\n        У вас еще нет заказов\n      ")]
                  )
                ]
              : [
                  _c(
                    "div",
                    { staticClass: "user-order-items" },
                    _vm._l(_vm.items, function(item, index) {
                      return _c("app-user-order-item", {
                        key: index,
                        attrs: {
                          item: item,
                          "link-component": _vm.linkComponent,
                          "object-url": _vm.objectUrl
                        }
                      })
                    }),
                    1
                  ),
                  _vm.hasNextPage
                    ? _c(
                        "div",
                        { staticClass: "text-center py-3" },
                        [
                          _c(
                            "app-button",
                            {
                              attrs: {
                                variant: "primary-light",
                                loading: _vm.nextPageloading
                              },
                              on: { click: _vm.fetchOrders }
                            },
                            [_vm._v("\n          Показать ещё\n        ")]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }