var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-form",
    {
      staticClass: "settings-section",
      attrs: {
        "request-handler": _vm.onSubmit,
        model: _vm.model,
        rules: _vm.validationRules
      },
      scopedSlots: _vm._u([
        {
          key: "button",
          fn: function(ref) {
            var loading = ref.loading
            return [
              _c(
                "app-button",
                { attrs: { loading: loading, type: "submit" } },
                [_vm._v("\n      Сохранить\n    ")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("h4", { staticClass: "mb-3" }, [
        _vm._v("\n    Настройки профиля\n  ")
      ]),
      _c(
        "div",
        { staticClass: "row mx-md-n3 m-0" },
        [
          _c(
            "app-form-group",
            {
              staticClass: "col-md-6 px-md-3 p-0",
              attrs: { "model-name": "name" }
            },
            [
              _c(
                "app-input",
                {
                  attrs: { placeholder: "Имя" },
                  model: {
                    value: _vm.model.name,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "name", $$v)
                    },
                    expression: "model.name"
                  }
                },
                [
                  _c("svg", [
                    _c("use", { attrs: { "xlink:href": "#icon-user" } })
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "app-form-group",
            {
              staticClass: "col-md-6 px-md-3 p-0",
              attrs: { "model-name": "surname" }
            },
            [
              _c(
                "app-input",
                {
                  attrs: { placeholder: "Фамилия" },
                  model: {
                    value: _vm.model.surname,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "surname", $$v)
                    },
                    expression: "model.surname"
                  }
                },
                [
                  _c("svg", [
                    _c("use", { attrs: { "xlink:href": "#icon-user" } })
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "row mx-md-n3 m-0" },
        [
          _c(
            "app-form-group",
            {
              staticClass: "col-md-6 px-md-3 p-0",
              attrs: { "model-name": "phone" }
            },
            [
              _c(
                "app-phone-number-input",
                {
                  attrs: { placeholder: "Телефон" },
                  model: {
                    value: _vm.model.phone,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "phone", $$v)
                    },
                    expression: "model.phone"
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        viewBox: "0 0 33.11 58.6",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M27.06,58.6h-21a6,6,0,0,1-6-5.94V5.93A6,6,0,0,1,6.05,0h21a6,6,0,0,1,6.05,5.93V52.66A6,6,0,0,1,27.06,58.6ZM6.05,4A2,2,0,0,0,4,5.93V52.66A2,2,0,0,0,6.05,54.6h21a2,2,0,0,0,2.05-1.94V5.93A2,2,0,0,0,27.06,4Z"
                        }
                      }),
                      _c("circle", {
                        attrs: { cx: "16.55", cy: "49.63", r: "2.86" }
                      })
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "app-form-group",
            {
              staticClass: "col-md-6 px-md-3 p-0",
              attrs: { "model-name": "email" }
            },
            [
              _c(
                "app-input",
                {
                  attrs: { type: "email", placeholder: "E-mail" },
                  model: {
                    value: _vm.model.email,
                    callback: function($$v) {
                      _vm.$set(_vm.model, "email", $$v)
                    },
                    expression: "model.email"
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        viewBox: "0 0 58.94 58.94",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d:
                            "M56.94,58.94H2a2,2,0,0,1-2-2V19.88a2,2,0,0,1,.91-1.67L28.38.32a2,2,0,0,1,2.18,0L58,18.21a2,2,0,0,1,.91,1.67V56.94A2,2,0,0,1,56.94,58.94ZM4,54.94H54.94V21L29.47,4.39,4,21Z"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          d:
                            "M29.47,40.41a2,2,0,0,1-1.12-3.66L55.82,18.23a2,2,0,1,1,2.24,3.31L30.59,40.07A2,2,0,0,1,29.47,40.41Z"
                        }
                      }),
                      _c("path", {
                        attrs: {
                          d:
                            "M29.47,40.41a2,2,0,0,1-1.12-.34L.88,21.54a2,2,0,1,1,2.24-3.31L30.59,36.75a2,2,0,0,1-1.12,3.66Z"
                        }
                      })
                    ]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }