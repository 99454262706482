






































import Vue from 'vue';
// import { mapState } from 'vuex';

// import AppObjectFilter from '@/components/ObjectFilter/ObjectFilter.vue';
// import AppUserOrderItem from './UserOrderItem.vue';

import AppUserOrders from 'shared/components/User/UserOrders/UserOrderItems.vue';
import { Location } from 'vue-router';

export default Vue.extend({
  components: {
    // AppObjectFilter,
    // AppUserOrderItem,
    AppUserOrders,
  },

  // computed: {
  //   ...mapState('user', ['items', 'loading']),
  // },

  // created() {
  //   this.$store.dispatch('user/loadOrders');
  // },

  methods: {
    // filterChange() {},

    objectUrl(item: UserOrderItem) {
      const routeLocation: Location = {
        name: 'object-detail',
      };

      if (item.ProductName === 'CheckOwner') {
        return { name: 'home' };
      } else {
        let objectKey = 'n/a';
        if (item.ItemMetadata.kadastr_number) {
          objectKey = item.ItemMetadata.kadastr_number;
        } else {
          objectKey = item.ItemMetadata.address;
        }

        routeLocation.params = {
          objectKey,
        };
      }

      return routeLocation;
    },
  },
});
