






































































import Vue from 'vue';
import AppAddPayerForm from 'shared/components/Payers/AddPayerForm.vue';
import AppEditPayerForm from 'shared/components/Payers/EditPayerForm.vue';
import Component from 'vue-class-component';
@Component
export default class Payers extends Vue {
  // props
  // data()
  payers: Payer[] = [];
  payersId: string[] = [];
  loading: boolean = true;

  // methods
  openAddPayerDialog() {
    this.$dialog.open({
      component: AppAddPayerForm,
      props: {
        onSubmit: this.addPayer,
      },
    });
  }

  openEditPayerDialog(payer: Payer) {
    this.$dialog.open({
      component: AppEditPayerForm,
      props: {
        onSubmit: this.editPayer,
        payerData: payer,
      },
    });
  }

  async addPayer(payer: Payer) {
    this.loading = true;
    try {
      await this.$api.payers.AddPayer(payer);
      this.$dialog.close();
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: 'Произошла ошибка при добавлении плательщика. Попробуйте ещё раз' });
    }
    this.getPayers();
  }

  async editPayer(payer: Payer) {
    this.loading = true;
    try {
      await this.$api.payers.UpdatePayerInfo(payer);
      this.$dialog.close();
      this.$noty.success({ text: 'Данные плательщика изменены' });
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: 'Произошла ошибка при редактировании плательщика. Попробуйте ещё раз' });
    }
    this.loading = false;
  }

  async removePayer(i: number) {
    this.loading = true;
    try {
      await this.$api.payers.RemovePayer({ payer_id: this.payersId[i] });
      this.$dialog.close();
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: 'Произошла ошибка при удалении плательщика. Попробуйте ещё раз' });
    }
    this.getPayers();
  }

  async getPayers() {
    this.payersId = await this.$api.payers.GetPayers();
    const payers: Payer[] = [];
    if (this.payersId.length) {
      for (const payerId of this.payersId) {
        try {
          const payer: Payer = await this.$api.payers.GetPayerInfo({ payer_id: payerId });
          payers.push(payer);
        } catch (error) {
          console.error(error);
          this.$noty.error({ text: 'Произошла ошибка при получении плательщиков' });
        }
      }
    }
    this.payers = payers;
    this.loading = false;
  }

  // lifecycle
  async mounted() {
    await this.getPayers();
  }
}

