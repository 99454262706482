






























































































import Vue from 'vue';
import Component from 'vue-class-component';
import pick from 'lodash/pick';
import assign from 'lodash/assign';
import { Validators } from '@/core/utils/validator';

@Component
export default class SettingsUpdateInfo extends Vue {
  // data()
  model: SimpleObject<string> = {
    name: '',
    email: '',
    phone: '',
    surname: '',
  };

  // lifecycle
  created() {
    this.model = pick(this.$store.state.auth.user, [ 'name', 'email', 'phone', 'surname' ]);
  }

  // methods
  validationRules(): ValidationConfig {
    return {
      // name: [Validators.required],
      // surname: [Validators.required],
      email: [ Validators.required, Validators.email ],
      phone: [ Validators.required, Validators.phone ],
    };
  }

  async onSubmit() {
    const user = assign(this.$store.state.auth.user, this.model);
    try {
      await this.$store.dispatch('user/updateInfo', user);
      this.$noty.info({ text: 'Профиль успешно обновлен' });
    } catch (error) {
      const text = (error as Error).message;
      this.$noty.error({ text });
    }
  }
}
