var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper page-wrapper_sm" },
    [
      _c(
        "div",
        { staticClass: "d-sm-flex align-items-center justify-content-between" },
        [
          _c("h4", { staticClass: "mb-sm-0 mb-3" }, [
            _vm._v("\n      Плательщики\n    ")
          ]),
          _c(
            "app-button",
            {
              attrs: { size: "sm", loading: _vm.loading },
              on: { click: _vm.openAddPayerDialog }
            },
            [_vm._v("\n      Добавить плательщика\n    ")]
          )
        ],
        1
      ),
      _vm._l(_vm.payers, function(payer, i) {
        return _c("div", { key: i, staticClass: "mt-4" }, [
          _c("div", { staticClass: "form-row align-items-center" }, [
            _c("div", { staticClass: "col-auto" }, [
              _c("b", { staticClass: "font-weight-500" }, [
                _vm._v(" " + _vm._s(payer.organisation_name) + " ")
              ])
            ]),
            _c(
              "div",
              { staticClass: "col-md-auto col-sm-12" },
              [
                _c(
                  "app-button",
                  {
                    staticClass: "small p-0",
                    attrs: { variant: "link" },
                    on: {
                      click: function($event) {
                        return _vm.openEditPayerDialog(payer)
                      }
                    }
                  },
                  [_vm._v("\n          редактировать\n        ")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-md-auto col-sm-12" },
              [
                _c(
                  "app-button",
                  {
                    staticClass: "small p-0",
                    attrs: { variant: "link" },
                    on: {
                      click: function($event) {
                        return _vm.removePayer(i)
                      }
                    }
                  },
                  [_vm._v("\n          удалить\n        ")]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "form-row" }, [
            _c("div", { staticClass: "col-auto text-truncate" }, [
              _vm._v("\n        ИНН " + _vm._s(payer.inn) + "\n      ")
            ])
          ])
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }