


























import Vue from 'vue';
import Component from 'vue-class-component';

import { AuthTypes } from 'shared/store/modules/auth/types';

@Component({
  popupOptions: {
    size: 'xs',
  },
})
export default class ConfirmAccountDeletion extends Vue {
  loading: boolean = false;

  // computed
  get user(): User {
    return this.$store.state.auth.user;
  }

  // methods
  async deleteAccount() {
    this.loading = true;
    this.user.thru_date = new Date().toISOString();
    try {
      await this.$api.user.UpdateInfo(this.user);
      this.$noty.success({ text: 'Аккаунт успешно удален' });
      this.$dialog.close();
      await this.$store.dispatch(AuthTypes.actions.LOGOUT);
    } catch (error) {
      console.error(error);
      this.$noty.error({ text: 'Произошла ошибка при удалении аккаунта, попробуйте еще раз' });
    }

    this.loading = false;
  }
}
