var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "payer-form" },
    [
      _c("app-dialog-close"),
      _c(
        "div",
        { staticClass: "popup__body" },
        [
          _vm._m(0),
          _c(
            "app-form",
            {
              staticClass: "mt-3",
              attrs: {
                model: _vm.payer,
                rules: _vm.validationConfig,
                "request-handler": _vm.submit
              },
              on: { "validation-error": _vm.showErrorMessage }
            },
            [
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "app-form-group",
                      {
                        staticClass: "required",
                        attrs: { label: "ИНН", "model-name": "inn" }
                      },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "text",
                            placeholder: "ИНН",
                            disabled: ""
                          },
                          model: {
                            value: _vm.payer.inn,
                            callback: function($$v) {
                              _vm.$set(_vm.payer, "inn", $$v)
                            },
                            expression: "payer.inn"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "app-form-group",
                      { attrs: { label: "КПП", "model-name": "kpp" } },
                      [
                        _c("app-input", {
                          attrs: { type: "text", placeholder: "КПП" },
                          model: {
                            value: _vm.payer.kpp,
                            callback: function($$v) {
                              _vm.$set(_vm.payer, "kpp", $$v)
                            },
                            expression: "payer.kpp"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-6" },
                  [
                    _c(
                      "app-form-group",
                      {
                        staticClass: "required",
                        attrs: { label: "ОГРН", "model-name": "ogrn" }
                      },
                      [
                        _c("app-input", {
                          attrs: { type: "text", placeholder: "ОГРН" },
                          model: {
                            value: _vm.payer.ogrn,
                            callback: function($$v) {
                              _vm.$set(_vm.payer, "ogrn", $$v)
                            },
                            expression: "payer.ogrn"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "app-form-group",
                      {
                        staticClass: "required",
                        attrs: {
                          label: "Наименование организации",
                          "model-name": "organisation_name"
                        }
                      },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "text",
                            placeholder: "Наименование организации",
                            disabled: ""
                          },
                          model: {
                            value: _vm.payer.organisation_name,
                            callback: function($$v) {
                              _vm.$set(_vm.payer, "organisation_name", $$v)
                            },
                            expression: "payer.organisation_name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "app-form-group",
                      {
                        staticClass: "required",
                        attrs: {
                          label: "Юридический адрес",
                          "model-name": "legal_address"
                        }
                      },
                      [
                        _c("app-input", {
                          attrs: { type: "text", placeholder: "Адрес" },
                          model: {
                            value: _vm.payer.legal_address,
                            callback: function($$v) {
                              _vm.$set(_vm.payer, "legal_address", $$v)
                            },
                            expression: "payer.legal_address"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "app-form-group",
                      {
                        staticClass: "required",
                        attrs: { label: "Руководитель", "model-name": "head" }
                      },
                      [
                        _c("app-input", {
                          attrs: { type: "text", placeholder: "Руководитель" },
                          model: {
                            value: _vm.payer.head,
                            callback: function($$v) {
                              _vm.$set(_vm.payer, "head", $$v)
                            },
                            expression: "payer.head"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "form-row" }, [
                _c("h6", [_vm._v(" Банковские реквизиты")])
              ]),
              _c("div", { staticClass: "form-row" }, [
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "app-form-group",
                      {
                        staticClass: "required",
                        attrs: { label: "БИК", "model-name": "bik" }
                      },
                      [
                        _c("app-input", {
                          attrs: { type: "text", placeholder: "БИК" },
                          model: {
                            value: _vm.payer.bik,
                            callback: function($$v) {
                              _vm.$set(_vm.payer, "bik", $$v)
                            },
                            expression: "payer.bik"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "app-form-group",
                      {
                        staticClass: "required",
                        attrs: {
                          label: "Расчетный счет",
                          "model-name": "checking_account"
                        }
                      },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "text",
                            placeholder: "Расчетный счет"
                          },
                          model: {
                            value: _vm.payer.checking_account,
                            callback: function($$v) {
                              _vm.$set(_vm.payer, "checking_account", $$v)
                            },
                            expression: "payer.checking_account"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "app-form-group",
                      {
                        staticClass: "required",
                        attrs: {
                          label: "Наименование банка",
                          "model-name": "bank_name"
                        }
                      },
                      [
                        _c("app-input", {
                          attrs: {
                            type: "text",
                            placeholder: "Наименование банка"
                          },
                          model: {
                            value: _vm.payer.bank_name,
                            callback: function($$v) {
                              _vm.$set(_vm.payer, "bank_name", $$v)
                            },
                            expression: "payer.bank_name"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "app-form-group",
                      {
                        staticClass: "required",
                        attrs: {
                          label: "Кор. счет",
                          "model-name": "correspondent_account"
                        }
                      },
                      [
                        _c("app-input", {
                          attrs: { type: "text", placeholder: "Кор. счет" },
                          model: {
                            value: _vm.payer.correspondent_account,
                            callback: function($$v) {
                              _vm.$set(_vm.payer, "correspondent_account", $$v)
                            },
                            expression: "payer.correspondent_account"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "form-row" }, [
                _c("h6", [_vm._v("Контактное лицо")]),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "app-form-group",
                      { attrs: { label: "ФИО", "model-name": "fio" } },
                      [
                        _c("app-input", {
                          attrs: { type: "text", placeholder: "ФИО" },
                          model: {
                            value: _vm.payer.fio,
                            callback: function($$v) {
                              _vm.$set(_vm.payer, "fio", $$v)
                            },
                            expression: "payer.fio"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "app-form-group",
                      { attrs: { label: "Телефон", "model-name": "phone" } },
                      [
                        _c("app-phone-number-input", {
                          model: {
                            value: _vm.payer.phone,
                            callback: function($$v) {
                              _vm.$set(_vm.payer, "phone", $$v)
                            },
                            expression: "payer.phone"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-md-12" },
                  [
                    _c(
                      "app-form-group",
                      { attrs: { label: "Email", "model-name": "email" } },
                      [
                        _c("app-input", {
                          attrs: { type: "text", placeholder: "Email" },
                          model: {
                            value: _vm.payer.email,
                            callback: function($$v) {
                              _vm.$set(_vm.payer, "email", $$v)
                            },
                            expression: "payer.email"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "form-row" },
                [
                  _c(
                    "app-button",
                    {
                      attrs: {
                        size: "lg",
                        type: "submit",
                        loading: _vm.loading
                      }
                    },
                    [_vm._v("\n          Сохранить\n        ")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "pb-3" }, [
      _c("h5", [_vm._v("Редактирование плательщика")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }