













import Vue from 'vue';
import Component from 'vue-class-component';
import AppChooseRoleDialog from 'shared/components/User/ChooseRoleDialog.vue';
@Component
export default class ChangeRole extends Vue {
  // props
  // props end
  // data()
  // data() end
  // lifecycle hooks
  // methods
  openDialog() {
    this.$dialog.open({ component: AppChooseRoleDialog });
  }
}
