var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-wrapper user-orders" },
    [
      _vm._m(0),
      _c("app-user-orders", {
        attrs: {
          "link-component": "router-link",
          "object-url": _vm.objectUrl,
          limit: 20
        }
      })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-wrapper-head user-orders__head" }, [
      _c("h4", { staticClass: "page-wrapper-head__title" }, [
        _vm._v("\n      Мои заказы\n    ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }