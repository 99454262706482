var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-order-item" }, [
    _c("div", { staticClass: "user-order-item__row" }, [
      _c(
        "div",
        { staticClass: "user-order-item__object-info user-order-item__cell" },
        [
          _vm.isWholesaleOrder
            ? [
                _c("div", { staticClass: "row align-items-center" }, [
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      {
                        staticClass: "user-order-item__cadastr",
                        on: { click: _vm.expand }
                      },
                      [
                        _vm._v("\n              Оптовый заказ "),
                        _c("i", { staticClass: "fa fa-angle-down fa-xs ml-1" })
                      ]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-auto d-md-none" },
                    [
                      _c(
                        "app-button",
                        {
                          attrs: {
                            variant: "white",
                            size: "sm",
                            loading: _vm.loading
                          },
                          nativeOn: {
                            click: function($event) {
                              return _vm.download(_vm.productZipLink, "zip")
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-download fa-s" })]
                      )
                    ],
                    1
                  )
                ])
              ]
            : [
                _vm.isCheckCreditRating
                  ? [
                      _c(
                        _vm.linkComponent,
                        {
                          tag: "component",
                          staticClass: "user-order-item__cadastr",
                          attrs: {
                            to: _vm.objectUrl(
                              _vm.item.OrderItem[0],
                              _vm.item.OrderItem[1]
                            )
                          }
                        },
                        [
                          _vm._v(
                            "\n            Проверка кредитного рейтинга\n          "
                          )
                        ]
                      )
                    ]
                  : _vm.isCheckOwner
                  ? [
                      _c(
                        _vm.linkComponent,
                        {
                          tag: "component",
                          staticClass: "user-order-item__cadastr",
                          attrs: { to: _vm.objectUrl(_vm.item.OrderItem[0]) }
                        },
                        [
                          _vm.isPhysical
                            ? [
                                _vm._v(
                                  "\n              Проверка физического лица\n            "
                                )
                              ]
                            : [
                                _vm._v(
                                  "\n              Проверка юридического лица\n            "
                                )
                              ]
                        ],
                        2
                      ),
                      _c(
                        "div",
                        { staticClass: "user-order-item__description" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.getFullName(_vm.item.OrderItem[0])) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  : _vm.isIndependentReport
                  ? [
                      _c(
                        _vm.linkComponent,
                        {
                          tag: "component",
                          staticClass: "user-order-item__cadastr",
                          attrs: { to: _vm.objectUrl(_vm.item.OrderItem[0]) }
                        },
                        [
                          _vm._v(
                            "\n            Проверка физчек эксперт\n          "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "user-order-item__description" },
                        [
                          _vm.getFullName(_vm.item.OrderItem[0])
                            ? [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.getFullName(_vm.item.OrderItem[0])
                                    ) +
                                    "\n            "
                                )
                              ]
                            : _vm._e(),
                          _vm.getField("inn_fiz")
                            ? [
                                _vm._v(
                                  "\n              ИНН:\n              " +
                                    _vm._s(_vm.getField("inn_fiz")) +
                                    "\n            "
                                )
                              ]
                            : _vm.getField("snils")
                            ? [
                                _vm._v(
                                  "\n              СНИЛС:\n              " +
                                    _vm._s(_vm.getField("snils")) +
                                    "\n            "
                                )
                              ]
                            : _vm.getField("inn_ur")
                            ? [
                                _vm._v(
                                  "\n              ИНН юр. лица:\n              " +
                                    _vm._s(_vm.getField("inn_ur")) +
                                    "\n            "
                                )
                              ]
                            : _vm.getField("ogrn")
                            ? [
                                _vm._v(
                                  "\n              ОГРН юр. лица:\n              " +
                                    _vm._s(_vm.getField("ogrn")) +
                                    "\n            "
                                )
                              ]
                            : _vm.getField("email")
                            ? [
                                _vm._v(
                                  "\n              Email:\n              " +
                                    _vm._s(_vm.getField("email")) +
                                    "\n            "
                                )
                              ]
                            : _vm.getField("vin")
                            ? [
                                _vm._v(
                                  "\n              VIN:\n              " +
                                    _vm._s(_vm.getField("vin")) +
                                    "\n            "
                                )
                              ]
                            : _vm.getField("auto_number")
                            ? [
                                _vm._v(
                                  "\n              Гос.номер:\n              " +
                                    _vm._s(_vm.getField("auto_number")) +
                                    "\n            "
                                )
                              ]
                            : _vm.getField("passport")
                            ? [
                                _vm._v(
                                  "\n              Паспорт:\n              " +
                                    _vm._s(_vm.getField("passport")) +
                                    "\n            "
                                )
                              ]
                            : _vm.getField("social_network")
                            ? [
                                _vm._v(
                                  "\n              По ссылке:\n              " +
                                    _vm._s(_vm.getField("social_network")) +
                                    "\n            "
                                )
                              ]
                            : _vm.getField("photo")
                            ? [
                                _vm._v(
                                  "\n              По изображению\n            "
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  : _vm.isCounterAgent
                  ? [
                      _c(
                        _vm.linkComponent,
                        {
                          tag: "component",
                          staticClass: "user-order-item__cadastr",
                          attrs: { to: _vm.objectUrl(_vm.item.OrderItem[0]) }
                        },
                        [
                          _vm._v(
                            "\n            Проверка контрагента\n          "
                          )
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "user-order-item__description" },
                        [
                          _vm.getFullName(_vm.item.OrderItem[0])
                            ? [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.getFullName(_vm.item.OrderItem[0])
                                    ) +
                                    "\n            "
                                )
                              ]
                            : _vm._e(),
                          _vm.getField("inn")
                            ? [
                                _vm._v(
                                  "\n              ИНН:\n              " +
                                    _vm._s(_vm.getField("inn")) +
                                    "\n            "
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  : _vm.isCheckAuto
                  ? [
                      _c(
                        _vm.linkComponent,
                        {
                          tag: "component",
                          staticClass: "user-order-item__cadastr",
                          attrs: { to: _vm.objectUrl(_vm.item.OrderItem[0]) }
                        },
                        [_vm._v("\n            Проверка авто\n          ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "user-order-item__description" },
                        [
                          _vm.get(
                            _vm.item,
                            "OrderItem.0.metadata.entityAuto.vin"
                          )
                            ? [
                                _vm._v(
                                  "\n              VIN: " +
                                    _vm._s(
                                      _vm.get(
                                        _vm.item,
                                        "OrderItem.0.metadata.entityAuto.vin"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            : _vm._e(),
                          _vm.get(
                            _vm.item,
                            "OrderItem.0.metadata.entityAuto.registration_number"
                          )
                            ? [
                                _vm._v(
                                  "\n              Гос. номер:\n              " +
                                    _vm._s(
                                      _vm.get(
                                        _vm.item,
                                        "OrderItem.0.metadata.entityAuto.registration_number"
                                      )
                                    ) +
                                    "\n            "
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    ]
                  : _vm.isCostAssessment
                  ? [
                      _c(
                        _vm.linkComponent,
                        {
                          tag: "component",
                          staticClass: "user-order-item__cadastr",
                          attrs: { to: _vm.objectUrl(_vm.item.OrderItem[0]) }
                        },
                        [_vm._v("\n            Оценка стоимости\n          ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "user-order-item__description" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.description) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  : [
                      _c(
                        _vm.linkComponent,
                        {
                          tag: "component",
                          staticClass: "user-order-item__cadastr",
                          attrs: { to: _vm.objectUrl(_vm.item.OrderItem[0]) }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.kadastrNumber) +
                              "\n          "
                          )
                        ]
                      ),
                      _vm.address
                        ? _c(
                            "div",
                            { staticClass: "user-order-item__description" },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.address) +
                                  "\n          "
                              )
                            ]
                          )
                        : _vm._e()
                    ]
              ]
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "user-order-item__date user-order-item__cell" },
        [
          _vm._v(
            "\n      " +
              _vm._s(_vm._f("date")(_vm.item.CreatedAt, "DD.MM.YYYY")) +
              "\n    "
          )
        ]
      ),
      _c(
        "div",
        { staticClass: "user-order-item__status user-order-item__cell" },
        [
          _c("span", { staticClass: "small text-muted" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.completedCount) +
                " / " +
                _vm._s(_vm.total) +
                "\n      "
            )
          ]),
          _c("div", { staticClass: "progress user-order-item__progress" }, [
            _c("div", {
              staticClass: "progress-bar user-order-item__progress-bar",
              style: _vm.progress
            })
          ])
        ]
      ),
      _c(
        "div",
        { staticClass: "user-order-item__actions user-order-item__cell" },
        [
          _vm.isWholesaleOrder
            ? _c("div", { staticClass: "row sm-gutters align-items-center" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "app-button",
                      {
                        attrs: {
                          variant: "white",
                          size: "sm",
                          block: "",
                          loading: _vm.loading
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.download(_vm.productZipLink, "zip")
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-download fa-s" })]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "app-button",
                      {
                        attrs: { variant: "white", size: "sm", block: "" },
                        on: { click: _vm.expand }
                      },
                      [_c("i", { staticClass: "fas fa-chevron-down fa-s" })]
                    )
                  ],
                  1
                )
              ])
            : _c(
                _vm.linkComponent,
                {
                  tag: "component",
                  attrs: { to: _vm.objectUrl(_vm.item.OrderItem[0]) }
                },
                [
                  _c(
                    "app-button",
                    { attrs: { variant: "white", size: "sm", block: "" } },
                    [
                      _c("span", [_vm._v("Детали")]),
                      _c("i", { staticClass: "fas fa-ellipsis-h fa-sm ml-1" })
                    ]
                  )
                ],
                1
              )
        ],
        1
      )
    ]),
    _vm.isExpanded
      ? _c(
          "div",
          { staticClass: "user-order-item-details" },
          _vm._l(_vm.item.OrderItem, function(orderItem) {
            return _c(
              _vm.linkComponent,
              {
                key: orderItem.OrderItemID,
                tag: "component",
                staticClass: "user-order-item-details__item",
                attrs: { to: _vm.objectUrl(orderItem) }
              },
              [
                _c("span", [
                  _vm._v(
                    "\n        " +
                      _vm._s(orderItem.ItemMetadata.kadastr_number) +
                      "\n        (" +
                      _vm._s(_vm.getName(orderItem.ProductName)) +
                      ")\n      "
                  )
                ]),
                _c("span", { staticClass: "user-order-item-details__status" }, [
                  _vm._v(_vm._s(_vm.getStatusText(orderItem.status)))
                ])
              ]
            )
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }