














import Vue from 'vue';
import Component from 'vue-class-component';
import AppConfirmAccountDeletion from '@/components/layout/Dialogs/ConfirmAccountDeletion.vue';

@Component
export default class AccountDeletion extends Vue {
  // props
  // props end
  // data()
  // data() end
  // lifecycle hooks
  // methods
  openDialog() {
    this.$dialog.open({ component: AppConfirmAccountDeletion });
  }
}
